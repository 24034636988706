import { computed, getCurrentInstance } from 'vue'
export function useComponentId(suffix) {
  let { uid } = getCurrentInstance()
  return computed(() => {
    return suffix ? `${uid}-${suffix}` : `${uid}`
  })
}

export function useCreateElement() {
  return getCurrentInstance().$createElement
}
