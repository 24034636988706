import { each, range } from 'lodash-es'

const maxHue = 360

export function getMaxHue() {
  return maxHue
}
export function toHex(x) {
  let hex = Math.round(x * 255).toString(16)
  return hex.length === 1 ? '0' + hex : hex
}
export function hue2rgb(p, q, t) {
  if (t < 0) {
    t += 1;
  } else if (t > 1) {
    t -= 1;
  }
  if (t < 1 / 6) {
    return p + (q - p) * 6 * t
  } else if (t < 1 / 2) {
    return q
  } else if (t < 2 / 3) {
    return p + (q - p) * (2 / 3 - t) * 6
  }
  return p
}
export function hsl2hex(h, s, l) {
  h /= maxHue
  s /= 100
  l /= 100
  let r, g, b

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    let q = l < 0.5 ? l * (1 + s) : l + s - l * s
    let p = 2 * l - q

    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }
  return '#' + toHex(r) + toHex(g) + toHex(b)
}
export function getColors(steps, saturation, luminance) {
  const colors = []
  if (!saturation) {
    saturation = 100
  }
  if (!luminance) {
    luminance = 66
  }
  if (steps > 0) {
    const hueSteps = Math.floor(maxHue / steps)
    each(range(steps), (index) => {
      const hue = index * hueSteps
      colors.push(hsl2hex(hue, saturation, luminance))
    });
  }
  return colors
}