<template>
  <div class="input-range input-date-range">
    <div class="input-date-range--start-date">
      <AppInputText v-model="startDateModel" type="date" :helper-text="startDateHelperText" :label="startDateLabel">
      </AppInputText>
    </div>
    <div class="input-date-range--dash">&dash;</div>
    <div class="input-date-range--end-date">
      <AppInputText v-model="endDateModel" type="date" :helper-text="endDateHelperText" :label="endDateLabel">
      </AppInputText>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import AppInputText from '@en-ui/components/AppInputText/AppInputText'
import { addDays, subDays, dateDiff, stringToDate, dateToString } from '@shared/utils/date'
import DateRange from '@shared/models/Date/Range'
import { isString, isObject } from 'lodash-es'

export default {
  name: 'AppInputDateRange',
  components: {
    AppInputText,
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: () => { },
    },
    label: {
      type: String,
      required: true,
      default: '',
    },
    endDateLabel: {
      type: String,
      required: false,
      default: ' ',
    },
    helperText: {
      type: String,
      required: false,
      default: '',
    },
    max: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ['update:modelValue', 'input'],
  setup(props, { emit }) {
    const startDate = ref('')
    const endDate = ref('')
    const startDateModel = computed({
      get() {
        return startDate.value
      },
      set(value) {
        onStartDateChange(value)
      },
    })
    const endDateModel = computed({
      get() {
        return endDate.value
      },
      set(value) {
        onEndDateChange(value)
      },
    })
    const startDateLabel = computed(() => {
      return props.label
    })
    const endDateLabel = computed(() => {
      return props.endDateLabel
    })
    const startDateHelperText = computed(() => {
      return props.helperText
    })
    const endDateHelperText = ref('')

    const onStartDateChange = (value) => {
      if (endDate.value === '') {
        endDate.value = value
      } else {
        let selectedDate = stringToDate(value)
        let diff = dateDiff(selectedDate, stringToDate(endDate.value))

        if (diff < 0) {
          endDate.value = value
        } else if (props.max && props.max > 0 && diff > props.max) {
          let newEndDate = addDays(selectedDate, props.max)
          endDate.value = dateToString(newEndDate)
        }
      }
      startDate.value = value
      emitDateRange()
    }
    const onEndDateChange = (value) => {
      if (startDate.value === '') {
        startDate.value = value
      } else {
        let selectedDate = stringToDate(value)
        let diff = dateDiff(stringToDate(startDate.value), selectedDate)

        if (diff < 0) {
          startDate.value = value
        } else if (props.max && props.max > 0 && diff > props.max) {
          let newStartDate = subDays(selectedDate, props.max)
          startDate.value = dateToString(newStartDate)
        }
      }
      endDate.value = value
      emitDateRange();
    }
    const initVmodel = () => {
      if (isObject(props.modelValue)) {
        if (isString(props.modelValue.startDate)) {
          startDate.value = props.modelValue.startDate
        }
        if (isString(props.modelValue.endDate)) {
          endDate.value = props.modelValue.endDate
        }
      }
    }
    const emitDateRange = () => {
      const newModelValue = new DateRange(startDate.value, endDate.value)
      emit('input', newModelValue)
      emit('update:modelValue', newModelValue)
    }
    const stopVmodelWatcher = watch(() => props.modelValue, (vModel) => {
      initVmodel()
    })

    onMounted(() => {
      initVmodel()
    })
    onBeforeUnmount(() => {
      stopVmodelWatcher()
    })

    return {
      startDateHelperText,
      endDateHelperText,
      startDateLabel,
      endDateLabel,
      startDateModel,
      endDateModel,
    }
  },
}
</script>

<style lang="postcss" scoped>
.input-date-range {
  @apply grid grid-cols-9;
}

.input-date-range--start-date {
  @apply col-span-4;
}

.input-date-range--dash {
  @apply text-center pt-12 col-span-1;
}

.input-date-range--end-date {
  @apply col-span-4;
}
</style>
