<template>
  <div ref="combobox">
    <div
      class="input input--with-right-icon input--with-left-icon"
      :class="{'has-error': validationHelpers.hasError}"
    >
      <!--eslint-disable-next-line vuejs-accessibility/label-has-for-->
      <label :id="validationHelpers.labelId" class="input-label">{{ label }}</label>
      <div class="input-wrapper">
        <div class="input-input" @click="toggleItems">{{ itemLabel || placeholder }}</div>
        <div class="input-right-icon">
          <AppSpinner :loading="loading" :size="12"></AppSpinner>
          <button type="button" @click="toggleItems">
            <AppIcon name="Toggle" v-if="!loading">
              <IconChevronUp :size="12" v-if="itemListHandler.show"/>
              <IconChevronDown :size="12" v-else/>
            </AppIcon>
          </button>
          &nbsp;
          <button type="button" v-if="allowReset" @click="resetItem">
            <AppIcon
              name="Reset"
              :size="12"
              v-if="itemLabel !== ''">
              <IconClose />
            </AppIcon>
          </button>
        </div>
      </div>
      <AppInputHelpers
        :validation="validation"
        :validation-helpers="validationHelpers"
        :helper-text="helperText"
      />
    </div>
    <div v-show="itemListHandler.show && items" class="search-items">
      <input
        class="input-input"
        type="text"
        v-model="itemListHandler.filter"
        v-if="showItemSearchInput"
        ref="searchInputField"
      />
      <AppRipple v-for="(item, idx) in items" :key="idx" class="search-item">
        <button type="button" @click="selectItem(item)">
          {{ item.text }}
        </button>
      </AppRipple>
      <button
        type="button"
        class="input-input"
        @click="loadMoreItems"
        v-if="showMoreItemsButton">{{ t('Label.Button.LoadMore') }}</button>
    </div>
  </div>
</template>

<script>
import AppIcon from '@en-ui/components/AppIcon/AppIcon'
import AppRipple from '@en-ui/components/AppRipple/AppRipple'
import AppSpinner from '@en-ui/components/AppSpinner/AppSpinner'
import AppInputHelpers from '@shared/components/AppInputHelpers/AppInputHelpers'
import { IconChevronUp, IconChevronDown, IconClose } from '@moneytransfer.ui/euronet-icons'
import { useValidationHelpers } from '@shared/composables/useValidationHelpers'
import { computed, reactive, ref, watch, onBeforeUnmount, onMounted, nextTick } from 'vue'
import { useComponentId } from '@shared/composables/useComponent'
import { find, filter, isEmpty, isString, isObject } from 'lodash-es'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AppInputComboBox',
  components: {
    AppInputHelpers,
    AppSpinner,
    AppRipple,
    AppIcon,
    IconChevronDown,
    IconChevronUp,
    IconClose,
  },
  emits: ['input','update:modelValue'],
  props: {
    modelValue: {
      type: [String, Object, Number],
      required: true,
      default: '',
    },
    label: {
      type: String,
      required: true,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    validation: {
      type: Object,
      default: () => {},
    },
    helperText: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    maxItems: {
      type: Number,
      default: 8,
    },
    options: {
      type: Array,
      required: true,
    },
    allowReset: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    // region component handling
    const validationHelpers = useValidationHelpers(props.validation)
    const id = useComponentId()
    const combobox = ref(null)
    const model = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('input', value)
        emit('update:modelValue', value)
      },
    })
    // endregion
    // region item handling
    const itemLabel = ref('')
    const selectItem = (item) => {
      itemListHandler.show = false
      model.value = props.returnObject ? item : item.value
    }
    const resetItem = () => {
      itemListHandler.show = false
      model.value = ''
    }
    const toggleItems = () => {
      if (!props.loading) {
        itemListHandler.show = !itemListHandler.show
      }
      if (itemListHandler.show && showItemSearchInput.value) {
        nextTick().then(() => {
          searchInputField.value.focus()
        })
      }
    }
    const loadMoreItems = () => {
      itemListHandler.max += props.maxItems
    }
    const itemListHandler = reactive({
      max: props.maxItems,
      show: false,
      filter: '',
    })
    const getFilteredItems = () => {
      return isEmpty(itemListHandler.filter) ? props.options : filter(props.options, (item) => {
        return item.text.toLowerCase().indexOf(itemListHandler.filter.toLowerCase()) >= 0
      })
    }
    const items = computed(() => {
      if (props.loading) {
        return []
      }
      let items = getFilteredItems()
      if (items.length > itemListHandler.max) {
        return items.slice(0, itemListHandler.max)
      }
      return items
    })
    const showMoreItemsButton = computed(() => {
      return (getFilteredItems()).length > itemListHandler.max
    })
    const showItemSearchInput = computed(() => {
      return props.options.length > itemListHandler.max
    })
    const searchInputField = ref(null)
    // endregion
    // region init
    const init = () => {
      itemLabel.value = ''

      let selected

      if (isString(props.modelValue) && props.modelValue !== '') {
        selected = find(props.options, {value: props.modelValue})
      }

      if (isObject(props.modelValue)) {
        selected = find(props.options, props.modelValue)
      }

      if (selected) {
        itemLabel.value = selected.text
      }
    }

    const handleClick = (e) => {
      if (combobox.value && !combobox.value.contains(e.target)) {
        itemListHandler.show = false
      }
    }

    let stopValueWatcher = watch(() => [props.modelValue, props.options], init)
    onMounted(() => {
      init()

      window.addEventListener('click', handleClick, true)
    })
    onBeforeUnmount(() => {
      stopValueWatcher()

      window.removeEventListener('click', handleClick, true)
    })
    // endregion
    return {
      resetItem,
      selectItem,
      toggleItems,
      loadMoreItems,
      validationHelpers,
      showMoreItemsButton,
      showItemSearchInput,
      searchInputField,
      itemListHandler,
      itemLabel,
      items,
      model,
      id,
      t,
      combobox,
    }
  },
}
</script>

<style lang="postcss" scoped>
.input-input {
  @apply pt-2;
}
.search-items {
  /* TODO: improve ! */
  position: absolute;
  z-index: 100;
  margin-top: -20px;
  max-height: 400px;
  width: calc(28rem - 50px); /* TODO !!!!!!!!!!!!!!!!!!!!! */
  background: white;
  box-shadow: 0px 3px 15px rgba(0, 17, 51, 0.05);
  text-overflow: ellipsis;
  overflow: auto;
  @apply p-4 rounded border border-gray-light;
}

.search-item {
  /* TODO: improve ! */
  text-overflow: ellipsis;
  @apply w-full px-2 py-1 border border-gray-light;
  @apply type-subtitle font-bold text-secondary-text whitespace-no-wrap;

  &:before {
    content: '';
    @apply absolute bottom-0 left-0 top-0;
    @apply h-14 w-1;
    @apply bg-orange rounded-r-md;
    @apply transition-all duration-270;
    @apply -ml-1;
  }
  svg {
    @apply relative top-1;
  }
  button {
    @apply w-full text-left;
  }
}
.search-item:nth-child(even) {
  @apply bg-gray-lighter;
}
</style>
