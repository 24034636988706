<template>
  <div class="chart">
    <canvas ref="canvas" :width="width" :height="height" @click="onCanvasClick($event)"></canvas>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, onUpdated } from 'vue'
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: 'AppChart',
  emits: ['select'],
  props: {
    type: {
      type: String,
      default: 'bar',
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    },
    width: {
      type: [String,Number],
      default: 300,
    },
    height: {
      type: [String,Number],
      default: 100,
    },
  },
  setup(props, { emit }) {
    // region canvas
    const canvas = ref(null)
    const onCanvasClick = (event) => {
      if (chart.value) {
        const element = chart.value.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false)
        const dataset = chart.value.getElementsAtEventForMode(event, 'dataset', { intersect: true }, false)
        if (element && element[0] && dataset) {
          emit('select', dataset)
        }
      }
    }
    // endregion
    // region chart
    const chart = ref(null)
    const initChart = async () => {
      const module = await import('chart.js/auto')
      destroyChart()
      if (module && module.default) {
        chart.value = new module.default(canvas.value, {
          type: props.type,
          data: props.data,
          options: props.options,
          plugins: [ChartDataLabels]
        })
      }
    }
    const destroyChart = () => {
      if (chart.value) {
        chart.value.destroy()
        chart.value = null
      }
    }
    // endregion
    onMounted(async () => {
      await initChart()
    })
    onUpdated(async () => {
      await initChart()
    })
    onBeforeUnmount(() => {
      destroyChart()
    })
    return {
      onCanvasClick,
      canvas,
    }
  },
}
</script>

<style lang="postcss" scoped>
.chart {
  @apply relative;
}
</style>
